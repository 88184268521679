// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ai-js": () => import("./../../../src/pages/ai.js" /* webpackChunkName: "component---src-pages-ai-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cozumler-enerji-yonetim-sistemi-js": () => import("./../../../src/pages/cozumler/enerji-yonetim-sistemi.js" /* webpackChunkName: "component---src-pages-cozumler-enerji-yonetim-sistemi-js" */),
  "component---src-pages-cozumler-js": () => import("./../../../src/pages/cozumler.js" /* webpackChunkName: "component---src-pages-cozumler-js" */),
  "component---src-pages-cozumler-toplam-ekipman-verimliligi-js": () => import("./../../../src/pages/cozumler/toplam-ekipman-verimliligi.js" /* webpackChunkName: "component---src-pages-cozumler-toplam-ekipman-verimliligi-js" */),
  "component---src-pages-cozumler-uretim-izleme-sistemi-js": () => import("./../../../src/pages/cozumler/uretim-izleme-sistemi.js" /* webpackChunkName: "component---src-pages-cozumler-uretim-izleme-sistemi-js" */),
  "component---src-pages-cozumler-yapay-zeka-destekli-uretim-js": () => import("./../../../src/pages/cozumler/yapay-zeka-destekli-uretim.js" /* webpackChunkName: "component---src-pages-cozumler-yapay-zeka-destekli-uretim-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-iletisim-js": () => import("./../../../src/pages/iletisim.js" /* webpackChunkName: "component---src-pages-iletisim-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-is-ve-zaman-etudu-egitim-kampanyasi-js": () => import("./../../../src/pages/is-ve-zaman-etudu-egitim-kampanyasi.js" /* webpackChunkName: "component---src-pages-is-ve-zaman-etudu-egitim-kampanyasi-js" */),
  "component---src-pages-kobigel-js": () => import("./../../../src/pages/kobigel.js" /* webpackChunkName: "component---src-pages-kobigel-js" */),
  "component---src-pages-moduller-izleme-ve-analiz-gercek-zamanli-izleme-js": () => import("./../../../src/pages/moduller/izleme-ve-analiz/gercek-zamanli-izleme.js" /* webpackChunkName: "component---src-pages-moduller-izleme-ve-analiz-gercek-zamanli-izleme-js" */),
  "component---src-pages-moduller-izleme-ve-analiz-raporlama-servisleri-js": () => import("./../../../src/pages/moduller/izleme-ve-analiz/raporlama-servisleri.js" /* webpackChunkName: "component---src-pages-moduller-izleme-ve-analiz-raporlama-servisleri-js" */),
  "component---src-pages-moduller-izleme-ve-analiz-veri-analizi-js": () => import("./../../../src/pages/moduller/izleme-ve-analiz/veri-analizi.js" /* webpackChunkName: "component---src-pages-moduller-izleme-ve-analiz-veri-analizi-js" */),
  "component---src-pages-moduller-js": () => import("./../../../src/pages/moduller.js" /* webpackChunkName: "component---src-pages-moduller-js" */),
  "component---src-pages-moduller-kullanici-arayuzu-rest-api-js": () => import("./../../../src/pages/moduller/kullanici-arayuzu/rest-api.js" /* webpackChunkName: "component---src-pages-moduller-kullanici-arayuzu-rest-api-js" */),
  "component---src-pages-moduller-kullanici-arayuzu-web-js": () => import("./../../../src/pages/moduller/kullanici-arayuzu/web.js" /* webpackChunkName: "component---src-pages-moduller-kullanici-arayuzu-web-js" */),
  "component---src-pages-moduller-uretim-zekasi-istatistiksel-proses-kontrolu-js": () => import("./../../../src/pages/moduller/uretim-zekasi/istatistiksel-proses-kontrolu.js" /* webpackChunkName: "component---src-pages-moduller-uretim-zekasi-istatistiksel-proses-kontrolu-js" */),
  "component---src-pages-moduller-uretim-zekasi-makine-ogrenimi-js": () => import("./../../../src/pages/moduller/uretim-zekasi/makine-ogrenimi.js" /* webpackChunkName: "component---src-pages-moduller-uretim-zekasi-makine-ogrenimi-js" */),
  "component---src-pages-moduller-uretim-zekasi-proses-analizi-js": () => import("./../../../src/pages/moduller/uretim-zekasi/proses-analizi.js" /* webpackChunkName: "component---src-pages-moduller-uretim-zekasi-proses-analizi-js" */),
  "component---src-pages-moduller-uretim-zekasi-proses-optimizasyonu-js": () => import("./../../../src/pages/moduller/uretim-zekasi/proses-optimizasyonu.js" /* webpackChunkName: "component---src-pages-moduller-uretim-zekasi-proses-optimizasyonu-js" */),
  "component---src-pages-moduller-veri-ve-kontrol-gecmise-donuk-veri-kaydi-hizmeti-js": () => import("./../../../src/pages/moduller/veri-ve-kontrol/gecmise-donuk-veri-kaydi-hizmeti.js" /* webpackChunkName: "component---src-pages-moduller-veri-ve-kontrol-gecmise-donuk-veri-kaydi-hizmeti-js" */),
  "component---src-pages-moduller-veri-ve-kontrol-nesnelerin-interneti-iot-js": () => import("./../../../src/pages/moduller/veri-ve-kontrol/nesnelerin-interneti-iot.js" /* webpackChunkName: "component---src-pages-moduller-veri-ve-kontrol-nesnelerin-interneti-iot-js" */),
  "component---src-pages-moduller-veri-ve-kontrol-veri-toplama-servisleri-js": () => import("./../../../src/pages/moduller/veri-ve-kontrol/veri-toplama-servisleri.js" /* webpackChunkName: "component---src-pages-moduller-veri-ve-kontrol-veri-toplama-servisleri-js" */),
  "component---src-pages-moduller-yonetim-bakim-ve-servis-yonetimi-js": () => import("./../../../src/pages/moduller/yonetim/bakim-ve-servis-yonetimi.js" /* webpackChunkName: "component---src-pages-moduller-yonetim-bakim-ve-servis-yonetimi-js" */),
  "component---src-pages-moduller-yonetim-ekipman-yonetimi-ve-oee-js": () => import("./../../../src/pages/moduller/yonetim/ekipman-yonetimi-ve-oee.js" /* webpackChunkName: "component---src-pages-moduller-yonetim-ekipman-yonetimi-ve-oee-js" */),
  "component---src-pages-moduller-yonetim-enerji-yonetimi-js": () => import("./../../../src/pages/moduller/yonetim/enerji-yonetimi.js" /* webpackChunkName: "component---src-pages-moduller-yonetim-enerji-yonetimi-js" */),
  "component---src-pages-moduller-yonetim-gorev-yonetimi-js": () => import("./../../../src/pages/moduller/yonetim/gorev-yonetimi.js" /* webpackChunkName: "component---src-pages-moduller-yonetim-gorev-yonetimi-js" */),
  "component---src-pages-moduller-yonetim-is-emri-planlamasi-js": () => import("./../../../src/pages/moduller/yonetim/is-emri-planlamasi.js" /* webpackChunkName: "component---src-pages-moduller-yonetim-is-emri-planlamasi-js" */),
  "component---src-pages-moduller-yonetim-kalite-yonetimi-js": () => import("./../../../src/pages/moduller/yonetim/kalite-yonetimi.js" /* webpackChunkName: "component---src-pages-moduller-yonetim-kalite-yonetimi-js" */),
  "component---src-pages-moduller-yonetim-kullanici-yonetimi-js": () => import("./../../../src/pages/moduller/yonetim/kullanici-yonetimi.js" /* webpackChunkName: "component---src-pages-moduller-yonetim-kullanici-yonetimi-js" */),
  "component---src-pages-moduller-yonetim-kural-ve-bildirim-yonetimi-js": () => import("./../../../src/pages/moduller/yonetim/kural-ve-bildirim-yonetimi.js" /* webpackChunkName: "component---src-pages-moduller-yonetim-kural-ve-bildirim-yonetimi-js" */),
  "component---src-pages-moduller-yonetim-recete-yonetimi-js": () => import("./../../../src/pages/moduller/yonetim/recete-yonetimi.js" /* webpackChunkName: "component---src-pages-moduller-yonetim-recete-yonetimi-js" */),
  "component---src-pages-moduller-yonetim-tuketim-yonetimi-js": () => import("./../../../src/pages/moduller/yonetim/tuketim-yonetimi.js" /* webpackChunkName: "component---src-pages-moduller-yonetim-tuketim-yonetimi-js" */),
  "component---src-pages-nasil-calisiyoruz-js": () => import("./../../../src/pages/nasil-calisiyoruz.js" /* webpackChunkName: "component---src-pages-nasil-calisiyoruz-js" */),
  "component---src-pages-rehber-js": () => import("./../../../src/pages/rehber.js" /* webpackChunkName: "component---src-pages-rehber-js" */),
  "component---src-pages-retmes-hakkinda-js": () => import("./../../../src/pages/retmes-hakkinda.js" /* webpackChunkName: "component---src-pages-retmes-hakkinda-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-default-template-js": () => import("./../../../src/templates/defaultTemplate.js" /* webpackChunkName: "component---src-templates-default-template-js" */)
}

